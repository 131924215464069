import Script from "next/script";
import React from "react";

type GoogleAnalyticsProps = {
	gaId: string;
};

export default function GoogleAnalytics(props: GoogleAnalyticsProps) {
	return (
		<>
			<Script
				strategy="lazyOnload"
				src={`https://www.googletagmanager.com/gtag/js?id=${props.gaId}`}
			/>
			<Script id="" strategy="lazyOnload">
				{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', "${props.gaId}", {
              page_path: window.location.pathname,
              });
          `}
			</Script>
		</>
	);
}

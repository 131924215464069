import "../styles/global.css";
import "../styles/_datepicker.css";
import "@fontsource-variable/montserrat";

import {
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import { useState } from "react";

import FeedbackProvider from "@/context/feedback";
import GoogleAnalytics from "@/components/analytic/GoogleAnalytics";
import AuthProvider from "../context/auth";

// polyfill for pdfjs
if (typeof Promise.withResolvers === "undefined") {
	if (typeof window !== "undefined") {
		// @ts-expect-error This does not exist outside of polyfill which this is doing
		window.Promise.withResolvers = () => {
			// biome-ignore lint/style/useSingleVarDeclarator: <explanation>
			// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
			let resolve, reject;
			const promise = new Promise((res, rej) => {
				resolve = res;
				reject = rej;
			});
			return { promise, resolve, reject };
		};
	} else {
		// @ts-expect-error This does not exist outside of polyfill which this is doing
		global.Promise.withResolvers = () => {
			// biome-ignore lint/style/useSingleVarDeclarator: <explanation>
			// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
			let resolve, reject;
			const promise = new Promise((res, rej) => {
				resolve = res;
				reject = rej;
			});
			return { promise, resolve, reject };
		};
	}
}

export default function App({ Component, pageProps }: AppProps) {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						// With SSR, we usually want to set some default staleTime
						// above 0 to avoid refetching immediately on the client
						staleTime: 60 * 1000,
					},
				},
			}),
	);
	return (
		<QueryClientProvider client={queryClient}>
			<GoogleAnalytics gaId="G-8HQVML1FT8" />
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
			<HydrationBoundary state={pageProps.dehydratedState}>
				<AuthProvider>
					<FeedbackProvider>
						<Component {...pageProps} />
					</FeedbackProvider>
				</AuthProvider>
			</HydrationBoundary>
		</QueryClientProvider>
	);
}

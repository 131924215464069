import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";

import { CONSTANTS } from "@/utils";

const { LS_KEY } = CONSTANTS;

interface Auth {
	auth: User;
	isAuthenticated: boolean;
	handleSetAuth: (user: User) => void;
	handleLogout: () => void;
	handleUpdateAuth: (returnPage?: string) => void;
	loadingLoadAuth: boolean;
}

const AuthContext = createContext<Auth>({} as Auth);

interface Props {
	children: ReactNode;
}

export default function AuthProvider({ children }: Props) {
	const [auth, setAuth] = useState<User>({} as User);
	const [loadingLoadAuth, setLoadingLoadAuth] = useState<boolean>(true);
	const router = useRouter();

	const isAuthenticated = Object.keys(auth).length !== 0;

	const handleSetAuth = (userData: User) => {
		setAuth(userData);
		if (typeof window !== "undefined" && window.localStorage) {
			const authCacheString = JSON.stringify(userData);
			localStorage.setItem(LS_KEY(), authCacheString);
		}
	};

	const handleLogout = () => {
		if (typeof window !== "undefined" && window.localStorage) {
			localStorage.removeItem(LS_KEY());
			setAuth({} as User);
		}
	};

	const handleUpdateAuth = (returnPage = "/login") => {
		if (typeof window !== "undefined" && window.localStorage) {
			const lsAuth = localStorage.getItem(LS_KEY()) || "";
			const authCache = lsAuth ? JSON.parse(lsAuth) : null;
			if (authCache) {
				setAuth(authCache);
			} else {
				router.push(returnPage);
			}
		}
	};

	useEffect(() => {
		setLoadingLoadAuth(true);
		if (typeof window !== "undefined" && window.localStorage) {
			const lsAuth = localStorage.getItem(LS_KEY()) || "";
			const authCache = lsAuth ? JSON.parse(lsAuth) : null;
			if (authCache) {
				setAuth(authCache);
			}
		}
		setLoadingLoadAuth(false);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				isAuthenticated,
				handleSetAuth,
				handleLogout,
				handleUpdateAuth,
				loadingLoadAuth,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	return useContext(AuthContext);
}

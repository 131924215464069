import "moment/locale/id";

import type { Product } from "@/types/product";
import moment from "moment";
import momentTimeZone from "moment-timezone";

moment.locale("id");

function dateInd(value: string) {
	return moment.utc(value).locale("id").format("Do MMMM YYYY");
}

function formatDateTraining(dateFrom: string, dateEnd: string) {
	let dateString = "";
	if (moment(dateFrom).format("Y-M-D") === moment(dateEnd).format("Y-M-D")) {
		dateString = dateInd(dateFrom);
	} else if (moment(dateFrom).format("M") !== moment(dateEnd).format("M")) {
		dateString = `${moment.utc(dateFrom).format("Do MMMM")} - ${moment
			.utc(dateEnd)
			.format("Do MMMM YYYY")}`;
	} else if (moment(dateFrom).format("Y-m") === moment(dateEnd).format("Y-m")) {
		dateString = `${moment.utc(dateFrom).format("Do")} - ${moment
			.utc(dateEnd)
			.format("Do MMMM YYYY")}`;
	} else if (moment(dateFrom).format("Y") === moment(dateEnd).format("Y")) {
		dateString = `${moment.utc(dateFrom).format("Do MMMM")} - ${dateInd(dateEnd)}`;
	} else {
		dateString = `${`${dateInd(dateFrom)} - ${dateInd(dateEnd)}`}`;
	}
	return dateString;
}

function formatPriceShort(number: number) {
	const formattedNumber = number.toLocaleString("id-ID", {
		style: "currency",
		currency: "IDR",
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	});

	return formattedNumber === "" ? "0" : `${formattedNumber}`;
}

function slugify(string: string) {
	return String(string)
		.normalize("NFKD") // split accented characters into their base characters and diacritical marks
		.replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.trim() // trim leading or trailing whitespace
		.toLowerCase() // convert to lowercase
		.replace(/,/g, "")
		.replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
		.replace(/\s+/g, "-") // replace spaces with hyphens
		.replace(/-+/g, "-"); // remove consecutive hyphens
}

const capitalizeFirstLetter = (string: string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

function IDR(value: number) {
	return value.toLocaleString("id-ID", {
		style: "currency",
		currency: "IDR",
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	});
}

function matchValue<TTarget, TData = Record<string, TTarget>>(
	arrayObject: Array<TData>,
	target: TTarget,
	propertyKey = "id",
) {
	if (target === null) return null;
	return arrayObject.find((val) => val[propertyKey] === target);
}

function dateWithFullMonth(date: string) {
	const dateMoment = moment(date).format("DD MMMM YYYY");
	return dateMoment;
}

const caseInsensitiveSort = (rowA: any, rowB: any) => {
	const a = rowA.name.toLowerCase();
	const b = rowB.name.toLowerCase();

	if (a > b) {
		return 1;
	}

	if (b > a) {
		return -1;
	}

	return 0;
};

const numberIsEven = (number: number) => number % 2 === 0;

const formatDateDatabase = (string: string) => {
	const date = moment(string).format("yyyy-MM-DD");
	return date;
};

const formatDateHuman = (string: string) => {
	const date = moment(string).format("DD/MM/yyyy");
	return date;
};

const isRegistrationClosed = (product: Product) => {
	const remainingSeats = Math.max(
		0,
		product.participant_capacity - product.participants.length,
	);

	let disableButton = false;

	// check if the product is already start
	const dateFrom = moment(product.date_from);
	const currentDateRaw = momentTimeZone(new Date())
		.utcOffset("+07:00")
		.format("YYYY-MM-DD HH:mm:ss");
	const currentDate = moment(currentDateRaw);

	if (currentDate.isAfter(dateFrom)) {
		disableButton = true;
	}
	// check if have another slot
	if (remainingSeats <= 0) {
		disableButton = true;
	}

	// check if now is date close training
	if (product.close_registration) {
		const dateCloseRegistration = moment(product.close_registration);

		if (currentDate.isAfter(dateCloseRegistration)) {
			disableButton = true;
		}
	}

	return disableButton;
};

// this is a list of clusters id that are taxed
// TODO: we should be able to mark the clusters as taxed or not
//       instead of hardcoding this
const TAXED_CLUSTERS = [
	40, // Bahasa: Tes TOEFL
	41, // Bahasa: Penerjemahan Dokumen
	65, // Bahasa: Tes IELTS
	66, // Bahasa: Tes EPT (Prediction)
	67, // Bahasa: Tes TOEIC
];

const TAXED_PRODUCTS = [2826, 2827, 2828];

export function isTaxedProduct(product: Product) {
  const isTaxedCluster = TAXED_CLUSTERS.includes(product.id_cluster);
  const isTaxedProduct = TAXED_PRODUCTS.includes(product.id);
	return isTaxedCluster || isTaxedProduct;
}

/**
 * Apply tax to the product price
 * @param product The product item
 * @param voucher The voucher amount
 * @returns
 */
export function applyTax(product: Product, voucher = 0) {
	// also take into account the number of participants
	const finalProductPrice = product.price * (product.count_participant ?? 1);
	if (!isTaxedProduct(product)) {
		return {
			finalPrice: finalProductPrice - voucher,
			tax: 0,
		};
	}

	const tax = Math.abs(finalProductPrice - voucher) * 0.11;
	const finalPrice = Math.abs(finalProductPrice - voucher + tax);

	return {
		finalPrice,
		tax,
	};
}

export const Helpers = {
	formatDateTraining,
	formatPriceShort,
	slugify,
	capitalizeFirstLetter,
	IDR,
	matchValue,
	dateWithFullMonth,
	caseInsensitiveSort,
	numberIsEven,
	formatDateHuman,
	formatDateDatabase,
	isRegistrationClosed,
};

const TOKEN = "token";

const PORTAL_TEST_BAHASA = "https://test.ipbtraining.com";
const VIDEO_ON_DEMAND = "https://online.ipbtraining.com";

// generate ls_key on client since we want to append the hostname
// if we don't do this, the key will be the same for all domain
// and resulting in conflicting sessions
// TODO(elianiva): replace with next-auth
function getSessionKey() {
	const hostname = window.location.hostname;
	return `ipbtraining:${hostname}:auth`;
}

const GENDER = [
	{ value: "L", label: "Bapak" },
	{ value: "P", label: "Ibu" },
];

const PORTOFOLIO = {
	technical: 1,
	ldp: 2,
	ila: 3,
};

const STUDY_OFFLINE = 1;
const STUDY_ONLINE = 2;

const REGEX_NPWP = [
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	"-",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

const PROFESI = [
	{
		label: "Dosen",
		value: "Dosen",
	},
	{
		label: "Peneliti",
		value: "Peneliti",
	},
	{
		label: "Dokter Hewan",
		value: "Dokter Hewan",
	},
	{
		label: "Paramedik veteriner",
		value: "Paramedik veteriner",
	},
	{
		label: "Mahasiswa pascasarjana",
		value: "Mahasiswa pascasarjana",
	},
	{
		label: "QA",
		value: "QA",
	},
	{
		label: "QC",
		value: "QC",
	},
	{
		label: "Operasional",
		value: "Operasional",
	},
	{
		label: "Marketing",
		value: "Marketing",
	},
	{
		label: "Keuangan",
		value: "Keuangan",
	},
	{
		label: "Akuntansi",
		value: "Akuntansi",
	},
	{
		label: "HR (SDM)",
		value: "HR (SDM)",
	},
	{
		label: "Legal",
		value: "Legal",
	},
	{
		label: "Administrasi",
		value: "Administrasi",
	},
	{
		label: "General Affairs",
		value: "General Affairs",
	},
	{
		label: "Salesperson",
		value: "Salesperson",
	},
	{
		label: "Laboran",
		value: "Laboran",
	},
	{
		label: "Data analist",
		value: "Data analist",
	},
	{
		label: "Sistem informasi",
		value: "Sistem informasi",
	},
	{
		label: "Teknologi informasi",
		value: "Teknologi informasi",
	},
	{
		label: "Lainnya",
		value: "Lainnya",
	},
];

const TYPE_TRANSACTION_TF_BANK = 1;
const TYPE_TRANSACTION_MIDTRANS = 2;
const CLUSTER_TEST_TRAINING = [40, 65, 66, 67];

export const CONSTANTS = {
	TOKEN,
	PORTAL_TEST_BAHASA,
	VIDEO_ON_DEMAND,
	PORTOFOLIO,
	GENDER,
	STUDY_OFFLINE,
	STUDY_ONLINE,
	REGEX_NPWP,
	PROFESI,
	TYPE_TRANSACTION_TF_BANK,
	TYPE_TRANSACTION_MIDTRANS,
	LS_KEY: getSessionKey,
	CLUSTER_TEST_TRAINING,
};

import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

interface Feedback {
	openModalFeedback: boolean;
	handleUpdateStatusModal: (status: boolean) => void;
}

const FeedbackContext = createContext<Feedback>({} as Feedback);

interface Props {
	children: ReactNode;
}

export default function FeedbackProvider({ children }: Props) {
	const [openModalFeedback, setOpenModalFeedback] = useState<boolean>(false);

	const handleUpdateStatusModal = (status: boolean) => {
		setOpenModalFeedback(status);
	};

	return (
		<FeedbackContext.Provider
			value={{
				openModalFeedback,
				handleUpdateStatusModal,
			}}
		>
			{children}
		</FeedbackContext.Provider>
	);
}

export function useFeedback() {
	return useContext(FeedbackContext);
}
